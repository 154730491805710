import request from '../utils/request';
export function changeDetector(id,data){
    return request({
        method:'PUT',
        url:`/common2/deviceInformation/${id}`,
        data,
    })
}

export function getBatchState(ids){
    return request({
        url: `/common2/devices/getDevicesState`,
        params:{
            ids
        }
    })
}

export function getHouse4store(params){
    return request({
        url:'/common1/house4store',
        params
    })
}

export function selectDevices(params){
    return request({
        url:'/common2/devices/selectDevices',
        params
    })
}

export function linkageLinkDevices(data,params){
    return request({
        method:'post',
        url:'/common2/linkage/linkDevices',
        data,
        params
    })
}

export function linkageSelectDevices(params){
    return request({
        method:'post',
        url:'/common2/linkage/selectDevices',
        params
    })
}


