//智能设备

const equipmentList = () => import('views/equipmentMatters/equipmentList.vue')
const contextualModel = () => import('views/equipmentMatters/contextualModel.vue')
const linkage = () => import('views/equipmentMatters/linkage.vue')
const controlSetUp = () => import('views/equipmentMatters/controlSetUp.vue')
const timingControl = () => import('views/equipmentMatters/timingControl.vue')
const equipmentWng = () => import('views/equipmentMatters/equipmentWng.vue')
const superuser = () => import('views/equipmentMatters/superuser')
const visualization = () => import('views/equipmentMatters/visualization.vue')
const roomControl = () => import('views/equipmentMatters/roomControl.vue')
const visualizationDevice = () => import('views/equipmentMatters/visualizationDevice.vue')
const identificationRecord =()=>import('views/equipmentMatters/identificationRecord.vue')



const Smart = [
	{
		//设备预警
		path: '/equipmentWng',
		name: 'equipmentWng',
		component: equipmentWng,
		meta: { requireAuth: true },
	},
	{
		//集控设置
		path: '/controlSetUp',
		name: 'controlSetUp',
		component: controlSetUp,
		meta: { requireAuth: true },
	},
	{
		//定时集控
		path: '/timingControl',
		name: 'timingControl',
		component: timingControl,
		meta: { requireAuth: true },
	},
	{
		//授权管理
		path: '/superuser',
		name: 'superuser',
		component: superuser,
		meta: { requireAuth: true },
	},
  {// 识别记录
		path: '/identificationRecord',
		name: 'identificationRecord',
		component: identificationRecord, 
		meta: { requireAuth: true }
	},
	{
		//联动管理
		path: '/linkage',
		name: 'linkage',
		component: linkage,
		meta: { requireAuth: true },
	},
	{
		//设备可视化
		path: '/visualization',
		name: 'visualization',
		component: visualization,
		meta: { requireAuth: true },
	},
	{
		//房间控制
		path: '/roomControl',
		name: 'roomControl',
		component: roomControl,
		meta: { requireAuth: true },
	},
	{
		//可视化设备操作
		path: '/visualizationDevice',
		name: 'visualizationDevice',
		component: visualizationDevice,
		meta: { requireAuth: true },
	},
]

export default Smart
