export const equipmentInfo = {
      airConditioner: {
        // 空调
        secondType: [4, 42, 43, 49,137,141],
        mode: {
          temperature: '',
          pattern: '',
          windSpeed: '',
        },
      },
      coolingAndHeatingLamp: {
        // 冷暖灯
        secondType: [31, 44, 56, 76, 97,98,99],
        mode: {
          brightness: 1,
          colorTemperature: 1,
        },
      },
      adjustingLamp: {
        // 调节灯
        secondType: [36, 59],
        mode: {
          brightness: 1,
        },
      },
      underfloorHeatingLamp: {
        // 地暖
        secondType: [57],
        mode: {
          brightness: 0,
        },
      },

      lnfraredAlarm: {
        // 红外
        secondType: [93, 95, 106],
        mode: {
          devId: '',
          switchs: '',
          spacing: '',
          threshold: '',
          sensitivity: '',
          periodVaule: '',
          thresholdVaule: '',
          sensitivityVaule: '',
        },
      },
      //窗帘
      percentageCurtain: {
        secondType: [117, 108],
        mode: {
          percentCurtain: 0,
        },
      },
      //单路隔离控制盒
      controlBox:{
        secondType:[144],
        mode: {
            delayTime:0,
            actionTime:0,
        },
      },
       //语音小管家
       chamberlainScene:{
        secondType:[27],
        mode: {
            tv:"",
            airConditioning:"",
            projector:"",
            infraCed:"",
        },
      },
      
}