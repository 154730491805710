//时间格式化
export function dateFtt(fmt, date) { //author: meizz 
    var o = {
        "M+": date.getMonth() + 1,     //月份
        "d+": date.getDate(),     //日
        "h+": date.getHours(),     //小时
        "m+": date.getMinutes(),     //分
        "s+": date.getSeconds(),     //秒
        "q+": Math.floor((date.getMonth() + 3) / 3), //季度
        "S": date.getMilliseconds()    //毫秒
    };
    if (/(y+)/.test(fmt))
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt))
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
}
//计算任务耗时
export function taskCostTime(t1, t2) {
    var costTime = '';
    t2 = t2 == "" ? getNowFormatDate() : t2;

    t1 = new Date(t1);
    t2 = new Date(t2);
    t1 = t1.getTime();
    t2 = t2.getTime();
    var subTime = parseInt((t2 - t1) / 1000 / 60);
    if (subTime < 60) {
        costTime = subTime + "分";
        return costTime;
    }

    if ((subTime / 60) >= 1 && (subTime / 60 / 24) < 1) {
        if (subTime % 60 > 0) {
            costTime = (subTime / 60).toString().split(".")[0] + "小时" + (subTime % 60) + "分";
        } else {
            costTime = (subTime / 60) + "小时";
        }
        return costTime;
    }
    if ((subTime / 60 / 24) >= 1) {
        if (parseInt(subTime / 60) % 24 >= 1) {
            costTime = (subTime / (60 * 24)).toString().split(".")[0] + "天" + (parseInt(subTime / 60) % 24) + "小时";
        } else {
            costTime = parseInt(subTime / (60 * 24)) + "天";
        }
        return costTime;
    }
}

export function DateAdd(interval, number, date) {
    /*
      *   功能:实现VBScript的DateAdd功能.
      *   参数:interval,字符串表达式，表示要添加的时间间隔.
      *   参数:number,数值表达式，表示要添加的时间间隔的个数.
      *   参数:date,时间对象.
      *   返回:新的时间对象.
      *   
      *     var   now   =   new   Date(); 
            //加五天. 
            var   newDate   =   DateAdd( "d",7,now); 
            console.log(newDate.getTime())
            //加两个月. 
            newDate   =   DateAdd( "m",2,now); 
            console.log(newDate.getTime())
            //加一年 
            newDate   =   DateAdd( "y",1,now); 
            console.log(newDate.getTime())
            //加1小时
            newDate   =   DateAdd( "h",1,now); 
            console.log(newDate.getTime())
      *   
      *---------------   DateAdd(interval,number,date)   -----------------
      */
    switch (interval) {
        case "y": {
            date.setFullYear(date.getFullYear() + number);
            return date;
            break;
        }
        case "q": {
            date.setMonth(date.getMonth() + number * 3);
            return date;
            break;
        }
        case "m": {
            date.setMonth(date.getMonth() + number);
            return date;
            break;
        }
        case "w": {
            date.setDate(date.getDate() + number * 7);
            return date;
            break;
        }
        case "d": {
            date.setDate(date.getDate() + number);
            return date;
            break;
        }
        case "h": {
            date.setHours(date.getHours() + number);
            return date;
            break;
        }
        case "m": {
            date.setMinutes(date.getMinutes() + number);
            return date;
            break;
        }
        case "s": {
            date.setSeconds(date.getSeconds() + number);
            return date;
            break;
        }
        default: {
            date.setDate(d.getDate() + number);
            return date;
            break;
        }
    }
}

export function DateMinus(date1, date2) {//date1:小日期   date2:大日期
    let days = date2 - date1;
    let day = parseInt(days / (1000 * 60 * 60 * 24));
    return day;
}

//生成审批编号
export function approvalNumber() {
    var strNumber = '';
    var myDate = new Date();
    var year = myDate.getFullYear();
    var month = myDate.getMonth() + 1;
    if (month >= 1 && month <= 9) {
        month = "0" + month;
    }
    var day = myDate.getDate();
    if (day >= 0 && day <= 9) {
        day = "0" + day;
    }
    var rnd = "";
    for (var i = 0; i < 6; i++) {
        rnd += Math.floor(Math.random() * 10);
    }
    var yearStr = year.toString().substring(2, 4);
    strNumber = yearStr + month + day + rnd;
    return strNumber;
}

//计算任务完成时间
export function getTaskTime(expectedTime) {

    var taskTime = formatTime(getNowFormatDate(), 2);

    var hopeSelect = expectedTime//期望时间的文本

    if (hopeSelect == "尽快" || hopeSelect == "今天" || hopeSelect == "电话联系"
        || hopeSelect == "") {
    } else if (hopeSelect == "明天") {
        var tomorrow = new Date(taskTime);
        var sDay = 1;
        taskTime = dateFtt('yyyy-MM-dd', new Date(tomorrow.setDate(tomorrow.getDate() + sDay)));
    } else if (hopeSelect == "后天") {
        var afterTomorrow = new Date(taskTime);
        var sDay = 2;
        taskTime = dateFtt('yyyy-MM-dd', new Date(afterTomorrow.setDate(afterTomorrow.getDate()
            + sDay)));
        console.log(taskTime);
    } else if (hopeSelect == "本周末") {
        var now = new Date();
        var day = now.getDay();
        var week = "1234567";
        var Saturday = 5 - week.indexOf(day);
        var satur = new Date();
        satur.setDate(satur.getDate() + Saturday);
        var sunday = 6 - week.indexOf(day);
        var sun = new Date();
        sun.setDate(sun.getDate() + sunday);
        taskTime = dateFtt('yyyy-MM-dd', new Date(sun));
    }
    return taskTime;
}

// 获取当前时间
function getNowFormatDate() {
    var date = new Date();
    var month = date.getMonth() + 1;
    var strDate = date.getDate();
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();
    if (month >= 1 && month <= 9) {
        month = "0" + month;
    }
    if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
    }
    if (hours >= 0 && hours <= 9) {
        hours = "0" + hours;
    }
    if (minutes >= 0 && minutes <= 9) {
        minutes = "0" + minutes;
    }
    if (seconds >= 0 && seconds <= 9) {
        seconds = "0" + seconds;
    }
    var currentdate = date.getFullYear() + "-" + month + "-" + strDate + " "
        + hours + ":" + minutes + ":" + seconds;
    return currentdate;
}

//自定义时间格式
export
function formatTime(time, type) {
    // console.log(time);
    var getTime = '';
    // 格式1：月-日 时:分 01-01 00:00
    if (type == '1') {
        var month = time.split("-")[1];
        var date = time.split("-")[2].split(" ")[0];
        var hour = time.split("-")[2].split(" ")[1].split(":")[0];
        var minute = time.split("-")[2].split(" ")[1].split(":")[1];
        getTime = month + "-" + date + " " + hour + ":" + minute;
    }
    // 格式2：年-月-日 2016-01-01
    if (type == '2') {
        var year = time.split("-")[0];
        var month = time.split("-")[1];
        var date = time.split("-")[2].split(" ")[0];
        getTime = year + "-" + month + "-" + date;
    }
    // 格式3：年月日时分秒 201601010000
    if (type == '3') {
        var year = time.split("-")[0];
        var month = time.split("-")[1];
        var date = time.split("-")[2].split(" ")[0];
        var hour = time.split("-")[2].split(" ")[1].split(":")[0];
        var minute = time.split("-")[2].split(" ")[1].split(":")[1];
        var second = time.split("-")[2].split(" ")[1].split(":")[2];
        getTime = year + month + date + hour + minute + second;
    }

    if (type == '4') {
        var year = time.split("-")[0];
        var month = time.split("-")[1];
        var date = time.split("-")[2].split(" ")[0];
        var hour = time.split("-")[2].split(" ")[1].split(":")[0];
        var minute = time.split("-")[2].split(" ")[1].split(":")[1];
        var second = time.split("-")[2].split(" ")[1].split(":")[2];
        getTime = year + "年" + month + "月" + date + "日 " + hour + ":" + minute;
    }

    if (type == '5') {
        var year = time.split("-")[0];
        var month = time.split("-")[1];
        var date = time.split("-")[2].split(" ")[0];
        var hour = time.split("-")[2].split(" ")[1].split(":")[0];
        var minute = time.split("-")[2].split(" ")[1].split(":")[1];
        var second = time.split("-")[2].split(" ")[1].split(":")[2];
        getTime = year + "年" + month + "月" + date + "日 " + hour + ":" + minute + ":" + second;
    }
    // 格式6：时分秒 000000
    if (type == '6') {
        var year = time.split("-")[0];
        var month = time.split("-")[1];
        var date = time.split("-")[2].split(" ")[0];
        var hour = time.split("-")[2].split(" ")[1].split(":")[0];
        var minute = time.split("-")[2].split(" ")[1].split(":")[1];
        var second = time.split("-")[2].split(" ")[1].split(":")[2];
        getTime = hour + minute + second;
    }
    return getTime;
}
//根据id获取名字
export function gerUserName(val, opt) {
    let username = ""
    for (let item of opt) {
        if (val == item.userCoding) {
            username = item.suStaffName;
            break;
        }
    }
    return username
}
//根据id获取部门和门店id
export function getUserId(arr, opt) {
    let res = []
    for (let i in arr) {
        for (let item of opt) {
            if (arr[i] == item.userCoding) {
                let demo = [item.storefrontId, item.departmentId, item.userCoding]
                res.push(demo)
            }
        }
    }
    return res
}
//根据id获取部门和门店id
export function getUserId2(arr, id) {
    var demo;
    for (let item of arr) {
            if (item.userCoding == id) {
                demo = [item.storefrontId, item.departmentId, item.userCoding]

            }
    }
    return demo
}
//获取星期几
export function getMyDay(date){
    let week;
    if(date.getDay()==0) week="星期日";
    if(date.getDay()==1) week="星期一";
    if(date.getDay()==2) week="星期二";
    if(date.getDay()==3) week="星期三";
    if(date.getDay()==4) week="星期四";
    if(date.getDay()==5) week="星期五";
    if(date.getDay()==6) week="星期六";
    return week;
}
