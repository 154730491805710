
//酒店风采
const honor = () => import('views/campus/honor.vue')
const information = () => import('views/campus/information.vue')
const party = () => import('views/campus/party.vue')
const results = () => import('views/campus/results.vue')
const team = () => import('views/campus/team.vue')
const Hotelrouter = [
  {//资讯
    path: '/information',
    name: 'information',
    component: information,
    meta: { requireAuth: true }
  },
  {//荣誉
    path: '/honor',
    name: 'honor',
    component: honor,
    meta: { requireAuth: true }
  },
  {//成果
    path: '/results',
    name: 'results',
    component: results,
    meta: { requireAuth: true }
  },
  {//党建
    path: '/party',
    name: 'party',
    component: party,
    meta: { requireAuth: true }
  },
  {//团队
    path: '/team',
    name: 'team',
    component: team,
    meta: { requireAuth: true }
  },
]

export default Hotelrouter