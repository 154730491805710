
<template>
    <el-cascader
      v-model="val"
      :options="options"
      @change="handleChange"
      ref="refHandle"
      :clearable="clearable"
      :disabled="disabled"
      :filterable="filterable"
      :props="{ expandTrigger: 'hover',checkStrictly: strictly }"
      :size="size"
    ></el-cascader>
</template>

<script>
  export default {
    name: 'cascader',
    props: {
      options: {
        type: Array
      },
      clearable: Boolean,
      strictly: {
        type: Boolean,
        default: false
      },
      value: {},
      filterable: Boolean,
      size: String,
      disabled: Boolean,
    },
    data() {
      return {
        val:this.value || null
      };
    },
    methods: {
      handleChange(value) {
        // console.log(value);
        // if(value.length === 1) {
        //   this.val = []
        // }
        // // this.$emit('change')
        // console.log(1)
        // console.log(this.val)
      }
    },
    
    watch: {
      val(n) {
        // console.log(n.length <= 1)
        // console.log(1)
        // if(n.length <=  1) {
        //   return
        // }
        this.$emit('input', n);
        this.$emit('change', n);
        if (this.$refs.refHandle) {
          this.$refs.refHandle.dropDownVisible = false; //监听值发生变化就关闭它
        }
        
      }
    },
    
  };
</script>
<style lang="scss">
 .el-cascader-panel{
    .el-radio__inner{
      border: 0px !important;
      background-color: transparent;
    }
    .el-radio__input.is-checked .el-radio__inner{
      background:none;
    }
    .el-radio{
      height: 100% !important;
      width: 150px;
      position: absolute;
      z-index: 10;
    }
}

</style>