//后勤管理
const assets = () => import('views/logisticsManagement/assets.vue')
const roomLibrary = () => import('views/logisticsManagement/roomLibrary.vue')
const publicArea = () => import('views/logisticsManagement/publicArea.vue')
const officeArea = () => import('views/logisticsManagement/officeArea.vue')
const supplierArea = () => import('views/logisticsManagement/supplierArea.vue')
const network = () => import('views/logisticsManagement/network')
const Package = () => import('views/logisticsManagement/package.vue')
const discount = () => import('views/logisticsManagement/discount.vue')
const vehicleManagement = () => import('views/logisticsManagement/vehicleManagement.vue')

const LogisticsRouter = [
  {//固定资产
    path: '/assets',
    name: 'assets',
    component: assets,
    meta: { requireAuth: true }
  },
  {//库房管理
    path: '/roomLibrary',
    name: 'roomLibrary',
    component: roomLibrary,
    meta: { requireAuth: true }
  },
  {//车辆管理
    path: '/vehicleManagement',
    name: 'vehicleManagement',
    component: vehicleManagement,
    meta: { requireAuth: true }
  },
  {//公共区域
    path: '/publicArea',
    name: 'publicArea',
    component: publicArea,
    meta: { requireAuth: true }
  },
  {//优惠管理
    path: '/discount',
    name: 'discount',
    component: discount,
    meta: { requireAuth: true }
  },
  {//套餐管理
    path: '/package',
    name: 'package',
    component: Package,
    meta: { requireAuth: true }
  },
  {//办公区域
    path: '/officeArea',
    name: 'officeArea',
    component: officeArea,
    meta: { requireAuth: true }
  },
  {//供应商管理
    path: '/supplierArea',
    name: 'supplierArea',
    component: supplierArea,
    meta: { requireAuth: true }
  },
  {//网络管理
    path: '/network',
    name: 'network',
    component: network,
    meta: { requireAuth: true }
  },
]

export default LogisticsRouter