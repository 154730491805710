import HotelManageRoute from './modules/Hotelmanagement'
import SmartRoute from './modules/smartDevice'
import FinanciaMana from './modules/FinancialManagement'
import LogisticsRouter from './modules/LogisticsManagement'
import AdministrativeManagement from './modules/AdministrativeManagement'
import Hotelrouter from './modules/HotelStyle'
const Login = () => import('views/Login.vue')
const Welcome = () => import('views/welcome.vue')
import main from 'views/main.vue'
const scanQRcode = () => import('components/phonePage/scanQRcode.vue')//扫房间码开门
const addDiscount = () => import('components/phonePage/addDiscount.vue')//折扣申请
//酒店管理

const roomState = () => import('views/hotelMatters/roomState.vue')
const order = () => import('views/hotelMatters/order.vue')
const vip = () => import('views/hotelMatters/vip.vue')
const room = () => import('views/hotelMatters/room.vue')
const changeShifts = () => import('views/hotelMatters/changeShifts.vue')
const channel = () => import('views/hotelMatters/channel.vue')

//智能设备

// const equipmentList = () => import('views/test/index.vue')
const equipmentList = () => import('views/equipmentMatters/equipmentList.vue')
const contextualModel = () => import('views/equipmentMatters/contextualModel.vue')

const controlSetUp = () => import('views/equipmentMatters/controlSetUp.vue')
// const controlSetUp = () => import('views/equipmentMatters/controlSetUp.vue')
const timingControl = () => import('views/equipmentMatters/timingControl.vue')
const equipmentWng = () => import('views/equipmentMatters/equipmentWng.vue')
const superuser = () => import('views/equipmentMatters/superuser')


//财务管理


//人事管理
const attendance = () => import('views/personnelMatters/attendance.vue')
const staff = () => import('views/personnelMatters/staff.vue')
const wechartRole = () => import('views/personnelMatters/wechartRole.vue')
//权限管理
const roleManagement = () => import('views/authorityManagement/roleManagement.vue')

//酒店设置
const hotelSettings = () => import('views/systemManagement/hotelSettings.vue')
//400+
const notfind401 = () => import('views/errorPage/401')
const notfind404 = () => import('views/errorPage/404')
//报表管理
const communityList = () => import('views/financialStatement/communityList.vue')
// const separateList=()=>import('views/financialStatement/separateList.vue')
//微信后台
const messageManagement = () => import('views/weChatAdmin/messageManagement.vue')
const fansManagement = () => import('views/weChatAdmin/fansManagement.vue')
const menuManagement = () => import('views/weChatAdmin/menuManagement.vue')
const routers = [
  {
    path: '/saas/:co',
    name: 'Login',
    component: Login
  },
  {//手机开锁
    path: '/scanQRcode',
    name: 'scanQRcode',
    component: scanQRcode
  },
  {//折扣申请
    path: '/addDiscount',
    name: 'addDiscount',
    component: addDiscount
  },
  {
    path: '/main',
    name: 'main',
    meta: { requireAuth: true },// 添加该字段，表示进入这个路由是需要登录的
    redirect: '/welcome',
    component: main,
    children: [
      {
        path: '/welcome',
        name: 'Welcome',
        component: Welcome,
        meta: { requireAuth: true }
      },
      ...HotelManageRoute, //酒店管理
      ...SmartRoute, //智能设备
      ...FinanciaMana,// 财务管理
      ...LogisticsRouter,
      ...AdministrativeManagement,
      ...Hotelrouter,
      {//交班接班
        path: '/changeShifts',
        name: 'changeShifts',
        component: changeShifts,
        meta: { requireAuth: true }
      },
      {//渠道管理
        path: '/channel',
        name: 'channel',
        component: channel,
        meta: { requireAuth: true }
      },
      {
        //场景管理
        path: '/contextualModel',
        name: 'contextualModel',
        component: contextualModel,
        meta: { 
          keepAlive: true,// 需要被缓存
          requireAuth: true
        },
      },
      {//设备列表
        path: '/equipmentList',
        name: 'equipmentList',
        component: equipmentList,
        meta: { 
            keepAlive: true ,// 需要被缓存
            requireAuth: true }
      },

      {//权限管理
        path: '/hotelSettings',
        name: 'hotelSettings',
        component: hotelSettings,
        meta: { requireAuth: true }
      },
      {//消息管理
        path: '/messageManagement',
        name: 'messageManagement',
        component: messageManagement,
        meta: { requireAuth: true }
      },
      {//粉丝管理
        path: '/fansManagement',
        name: 'fansManagement',
        component: fansManagement,
        meta: { requireAuth: true }
      },
      {//菜单管理
        path: '/menuManagement',
        name: 'menuManagement',
        component: menuManagement,
        meta: { requireAuth: true }
      },
      {//考勤管理
        path: '/attendance',
        name: 'attendance',
        component: attendance,
        meta: { requireAuth: true }
      },
      {//教师管理
        path: '/staff',
        name: 'staff',
        component: staff,
        meta: { requireAuth: true }
      },
      {//微信权限管理
        path: '/wechartRole',
        name: 'wechartRole',
        component: wechartRole,
        meta: { requireAuth: true }
      },

      {//没有权限
        path: '/notfind401',
        name: 'notfind401',
        meta: { requireAuth: true },
        component: notfind401
      },
      {//权限管理
        path: '/roleManagement',
        name: 'roleManagement',
        component: roleManagement,
        meta: { requireAuth: true }
      },
      {//小区列表
        path: '/communityList',
        name: 'communityList',
        component: communityList,
        meta: { requireAuth: true }
      },
    ]
  },
  {
    path: '*',
    name: 'notfind404',
    component: notfind404
  }
]

export default routers