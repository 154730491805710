//行政管理
const calendar = () => import('views/administrativeManagement/calendar.vue')
const accraditation = () => import('views/administrativeManagement/accraditation.vue')
const maintenance = () => import('views/administrativeManagement/maintenance.vue')
const task = () => import('views/administrativeManagement/task.vue')
const log = () => import('views/administrativeManagement/log.vue')
const plan = () => import('views/administrativeManagement/plan.vue')
const announcement = () => import('views/administrativeManagement/announcement.vue')
const personalSettings = () => import('views/systemManagement/personalSettings.vue')
const Visitors = () => import('views/administrativeManagement/Visitors.vue')

// 行政管理路由
const AdminStra = [
  {//日历
    path: '/calendar',
    name: 'calendar',
    component: calendar,
    meta: { requireAuth: true }
  },
  {//公告
    path: '/announcement',
    name: 'announcement',
    component: announcement,
    meta: { requireAuth: true }
  },
  {//房间设置
    path: '/personalSettings',
    name: 'personalSettings',
    component: personalSettings,
    meta: { requireAuth: true }
  },
  {//审批
    path: '/accraditation',
    name: 'accraditation',
    component: accraditation,
    meta: { requireAuth: true }
  },
  {//任务
    path: '/task',
    name: 'task',
    component: task,
    meta: { requireAuth: true }
  },
  {//日志
    path: '/log',
    name: 'log',
    component: log,
    meta: { requireAuth: true }
  },
  {//计划
    path: '/plan',
    name: 'plan',
    component: plan,
    meta: { requireAuth: true }
  },
  {//维保
    path: '/maintenance',
    name: 'maintenance',
    component: maintenance,
    meta: { requireAuth: true }
  },
  {//访客预约
    path: '/Visitors',
    name: 'Visitors',
    component: Visitors,
    meta: { requireAuth: true }
  },
]

export default AdminStra