<template>
  <el-submenu :index="index">
    <template slot="title">
      <i :class="item.smIcon"></i>
      <span slot="title">{{ item.smMenuName }}</span>
    </template>
    <el-menu-item
      v-show="item2.smMenuType !== 0"
      v-for="item2 in item.childrens"
      :key="item2.smAlias"
      :index="item2.smRouter"
      @click="submenuClick(item2)"
      >{{ item2.smMenuName }}</el-menu-item
    >
    <el-submenu
      v-show="item2.smMenuType == 0"
      v-for="item2 in item.childrens"
      :index="item2.smMenuName"
      :key="item2.smAlias + '1'"
    >
      <template slot="title">
        <i :class="item2.smIcon"></i>
        <span slot="title">{{ item2.smMenuName }}</span>
      </template>
      <el-menu-item
        v-for="item3 in item2.childrens"
        :key="item3.smAlias"
        :index="item3.smRouter"
        @click="submenuClick(item3)">
        {{ item3.smMenuName }}
        </el-menu-item>
    </el-submenu>
  </el-submenu>
</template>

<script>
export default {
  data () {
    return {
      co:sessionStorage.getItem("co"),
      //外链
      linkRouter: [  
        "/hangweiyujing",
        "/shebeiyujing",
        "/faceLocal",
        "/faceCloud",
      ],
    }
  },
  props: {
    item: {
      type: Object
    },
    index: {
      type: String
    }
  },
  methods: {
    submenuClick(val) {
      //其他系统入口
      let username, password;
      if (this.linkRouter.includes(val.smRouter)) {
        username = sessionStorage.getItem("account");
        password = sessionStorage.getItem("password");
        this.co = sessionStorage.getItem("co");
      }
      val.smRouter == "/hangweiyujing" && window.open(`http://86xc.net/warning-admin/#/login/${this.co}?username=${username}&password=${password}`);
      val.smRouter == "/shebeiyujing" && window.open(`http://ews.86xc.net/login/${this.co}?username=${username}&password=${password}`);
      val.smRouter == "/faceLocal" && window.open(`${this.bakorServerDomainName}/faceIdentiff/#/`);
      val.smRouter == "/faceCloud" && window.open(`${this.bakorStandbyDomainName}/faceIdentiff/#/`);
    },
  }
};
</script>

<style></style>
