import Axios from 'axios'
import { decrypt, encrypt } from './recharge'
// 根据打包环境判断baseURL
const ENCRYPTION = false //加密开关
Axios.defaults.baseURL = (process.env.VUE_APP_TITLE == 'online' || process.env.VUE_APP_TITLE == 'testing') ? `/` : '/api',
Axios.defaults.withCredentials = true
Axios.defaults.headers['Content-Type'] = 'application/json;charset=UTF-8'
Axios.interceptors.request.use(
  request => {
    // console.log(request)
    // console.log(request.data)
    if (ENCRYPTION && request.url === '/common1/users' && request.method === 'get') {
      request.url = '/common1/users/list'
    }
    if (ENCRYPTION && !(request.method === 'get' && request.params)) {
      if (request.data) {
        request.data = encrypt(JSON.stringify(request.data))
      } else {
        request.params = encrypt(JSON.stringify(request.params))
      }
    }

    let token = sessionStorage.getItem('token')
    if (token) {
      request.headers['Authorization'] = token;
    }

    return request;
  },
  error => {
    return Promise.reject(error);
  }
)
// 添加响应拦截器
Axios.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  if (ENCRYPTION && (response.data && !response.data.status)) {
    response.data = JSON.parse(decrypt(response.data))
  }
  // console.log(response)
  return response;
}, function (error) {
  // 对响应错误做点什么
  return Promise.reject(error);
});

export default Axios