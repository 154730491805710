//酒店管理
const roomState = () => import('views/hotelMatters/roomState.vue')
const order = () => import('views/hotelMatters/order.vue')
const vip = () => import('views/hotelMatters/vip.vue')
const room = () => import('views/hotelMatters/room.vue')
const changeShifts = () => import('views/hotelMatters/changeShifts.vue')
const channel = () => import('views/hotelMatters/channel.vue')



const HotelManage = [
  {//房态图
    path: '/roomState',
    name: 'roomState',
    component: roomState,
    meta: { requireAuth: true },

  },
  {//订单查询
    path: '/order',
    name: 'order',
    component: order,
    meta: { requireAuth: true }
  },
  {//会员管理
    path: '/vip',
    name: 'vip',
    component: vip,
    meta: { requireAuth: true }
  },
  {//房间管理
    path: '/room',
    name: 'room',
    component: room,
    meta: { requireAuth: true }
  },
  
]

export default HotelManage