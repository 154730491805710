const guestAccount = () => import('views/hotelMatters/guestAccount.vue')
const settleAccounts = () => import('views/hotelMatters/settleAccounts.vue')

const router = [
  {//客账管理
    path: '/guestAccount',
    name: 'guestAccount',
    component: guestAccount,
    meta: { requireAuth: true }
  },
  {//挂账管理
    path: '/settleAccounts',
    name: 'settleAccounts',
    component: settleAccounts,
    meta: { requireAuth: true }
  },
]

export default router