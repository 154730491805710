<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
  export default {

  }
</script>

<style lang="scss">
  .Part .el-button--mini{
    margin: 5px!important;
    font-size: 16px;
  }
  // 下拉框
  .el-cascader-menu__wrap{
    height: 100% !important;
    min-height: 100px;
    max-height:300px !important;
  }
//   html{
//       -moz-user-select: none;
//       -webkit-user-select: none;
//       -ms-user-select: none;
//       -khtml-user-select: none;
//       user-select: none;
//   }
  +  body{
    font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
  "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  }
  //按钮黑色
  .Part .el-button--mini{
    margin: 5px!important;
    font-size: 16px!important;
  }
  .elMain{
    min-width: 1200px;
  }
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  //滚动条的滑块
  ::-webkit-scrollbar-thumb {
    background-color: #a1a3a9;
    border-radius: 5px;
  }
  .contextmenu {
    margin: 0;
    background: #fff;
    z-index: 999999999999;
    position: absolute;
    list-style-type: none;
    padding: 0;
    border-radius: 0px;
    font-size: 12px;
    font-weight: 400;
    color: #333;
    li {
      margin: 0;
      text-align:center;
      padding: 0px 16px;
      line-height: 25px;
      cursor: pointer;
      &:hover {
        background: #eee;
      }
    }
  }

  .hoverbox{
    color: #fff
  }
  .el-container .el-aside .el-menu{
    border: 0;
  }
  .el-container .el-aside .el-menu li{
    background-color: #1f2d3d!important;
  }
  .el-menu-item{
    width: 190px;
  }
  .el-menu--collapse{
    width: 65px !important;
  }
  .el-menu-vertical-demo {
    width: 190px;
    min-height: 100%;
    .el-submenu{
      i{
        color:#fff
      }
      .el-menu-item {
        height: 50px;
        line-height: 50px;
        padding: 0 45px;
        min-width: 190px;
      }
    }
  }
  .input{
    color: #363738;
    padding: 5px 5px 3px;
    margin-bottom: 3px;
    box-shadow: 0 1px 2px 1px #dfdfdf;
    .el-row{
    font-size: 12px !important;
    }
  }  
  .current-row > td {
    background: rgba(0, 158, 250, 0.2) !important;
  }
  .switchStyle .el-switch__label {
    position: absolute;
    display: none;
    text-align: center;
    color: #fff !important;
    span {
      font-size: 10px;
    }
  }
  /*打开时文字位置设置*/
  .switchStyle .el-switch__label--right {
    z-index: 1;
    right: 5px;
  }
  /*关闭时文字位置设置*/
  .switchStyle .el-switch__label--left {
    z-index: 1;
    left: 7px;
  }
  /*显示文字*/
  .switchStyle .el-switch__label.is-active {
    display: block;
  }
  .switchStyle.el-switch .el-switch__core,
  .el-switch .el-switch__label {
    width: 60px !important;
  }
  .transferStyle .el-transfer-panel{
    width: 380px;
  }
  .transferStyle .el-transfer-panel__item{
    display: block;
  }
  .el-tag--dark.el-tag--success{
    background-color: #64d9d6!important;
    border-color: #64d9d6!important;
  }
.Part {
  .project-detail {
    .title {
      color: #333;
      font-size: 18px;
      margin: 14px 0 13px;
      span {
        color: #999999;
        font-size: 12px;
        margin-right: 10px;
        font-weight: normal;
      }
    }
  }
}
.el-form {
  // margin-bottom: 15px;
  .el-form-item {
    margin: 0;
    padding-bottom: 10px;
    .el-form-item__label {
      padding: 0 5px 0 0;
    }
    .el-form-item__content {
      position: relative;
      line-height: 40px;
    }
    .el-form-item__error {
      line-height: 1;
      padding-top: 0;
      font-size: 10px;
      position: absolute;
      top: 100%;
      left: 0;
    }
  }
}
</style>
