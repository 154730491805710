import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import axios from 'axios'
import contextualModelChild from './views/equipmentMatters/contextualModel-cpns/batchAdditionDialog/store'
// import learnToUseVuex from './views/equipmentMatters/components/sceneManagement/addSeneComponents/store'

export default new Vuex.Store({

  state: {
    setTriggerActionState:[],
    staTaShow:false,
    staEquipmentShow:false,
    staDevicesShow:false,
    setMechaningShow:false,
    setEquipmenData:[],
    setEqContent:[],
    // -----------------------------
    userName: [],
    userNameList: [],
    roomTypeList: [],
    setupList: [],
    jcuGroupType: [],
    cityList: [],     
    currentUserInfo: {},
    detailsBudget: [],
    detailsBudgetList: [], //三级联收支列表
    companyVars:{//变量表数据
      bankList:[],//银行列表
      suppliesType:[],//资产类型列表
      assetsTypeList: [], //资产名称列表
      planTypeList:[],//水电气列表
      planList:[],//激活水电气
      taskTypeList:[],//任务类型列表
      paymentTypeList: [], //账户类型数组   
      detailsBudgetList: [], //三级联收支列表
      intendedSourceList: '', //意向人来源
      lateFeeRate: "", // 滞纳金费率
      achieveSort: [], //业绩类型
      achieveType: [], //业绩类别
      achieveValidate: [], //收益类型
      userType:[],//岗位人员
      wxpayAccount:'',//微信收款默认账户
      chargeReminder: '', //微信手续费提醒文案
      chargePercentage: '', //微信手续费比例
      wxgzhName:'',//微信公众号
      customerServiceTel: '',//客服电话
      companyAbbreviation: '',//公司简称
      auditSwitch: '', //收支审核复核
      moneySwitch: '', //定金
      moneyValues: '', //定金数额
      forcedFollowupSwitch: '', //强写跟进
      forcedFollowupValues: '', //查看业主不写跟进
      contractNums: '', //合同编号检测
      billNum: '', //票据编号检测
      comfirmNum: '', //确认书编号检测
      doorplateno: '', //门牌号规则检测
      contractRiskControl: '', //合同风控
      autoSendMessage: '', //自动发送短信
      autoSendMessageDays: '', //自动发送短信提前天数
      maxOverdue: '', //欠费风控
      maxOverdueDays: '', //欠费逾期天数
      timeOnAndOff: '', //收租时间
      timeScope: '', //收租时间范围
      meterReadingSwitch: '', //自动抄表
      meterReadingTimes: '', //自动抄表时间
      doorLockAuthorization: '', //门锁二次授权
      onDutyRepairer: '', //维修值班人员
      onDutyRepairerSwitch: '', //开启维修值班人员
      gzhAd:{},//微信公众号广告图片设置
      sectionTypeList:[],//房型
      waterContinuityVariable: '', //当日水用量高于昨日用量预警变量
      waterDailyVariable: '', //连续时间（分钟）用水预警变量
      doorCardSystem: '', //门卡进制设置
    },
    //菜单列表
    menuList: [],
    //权限
    authe: {},
    //页面权限
    pageAuth: {},
    wxTags:[],
    imageUrl: '',
  },

  getters: {        //这里是get方法

  },

  mutations: {
    getTriggerActionMut(state,data){
      console.log(data)
      state.setTriggerActionState = data
    },
    getTaShowMut(state,value){
      state.staTaShow = value
    },
    getEquipmentMut(state,value){
      console.log(value)
      state.staEquipmentShow = value
    },
    getDevicesMut(state,value){
      console.log(value)
      state.staDevicesShow = value
    },
    getMechaningMut(state,setMechaningMut){
      // console.log(setMechaningMut)
      state.setMechaningShow =  setMechaningMut
    },
   
    // getEquipmentUse: (state,setEquipmenData) => {
    //   state.setEquipmenData = setEquipmenData
    // },
    // getEqBus: (state,setEqContent) => {
    //   state.setEqContent = setEqContent
    // },
    // -----------------------------------
    getUserName: (state,userName) => {
      state.userName = userName
    },
    getUserNameList: (state,userNameList) => {
      state.userNameList = userNameList
    },
    getSetupList: (state,setupList) => {
      state.setupList = setupList
    },
    getGroupType: (state,jcuGroupType) => {
      state.jcuGroupType = jcuGroupType
    },
    getCity: (state,cityList) => {
      state.cityList = cityList
    },
    getUserInfo(state,currentUserInfo) {
      state.currentUserInfo = currentUserInfo
    },
    getDetailsBudget: (state, detailsBudget) => {
      state.detailsBudget = detailsBudget
    },
    getCompanyVars(state, companyVars) {
      state.companyVars = companyVars
    },
    getDetailsBudgetList: (state, detailsBudgetList) => {
      state.detailsBudgetList = detailsBudgetList
    },
    getMenuList(state,menuList){
      state.menuList = menuList
    },
    getAuthorityList(state,authe){
      state.authe = authe
    },
    getPageAuth(state,pageAuth){
      state.pageAuth = pageAuth
    },
    setwxTags:(state,tags) => {
      state.wxTags = tags
    },
    setImageUrl(state, url) {
      state.imageUrl = url
    }
  },

  actions: {
    getEquipment: (context,ccc) => {
      return axios({
        url:"/ems/secondType/findAllSecond",
        method:"GET",
        params:{
          idList:ccc
        }
      }).then(res=>{
        let arr =res.data
        context.commit('getEquipmentUse',arr)
      })
    },
    getStatusBus: (context,bbb) =>{
      return axios({
        url:`/ems/deviceFunctionState/selectDFState`,
        method:"GET",
        params:{
          dfsId:bbb
        }
      }).then(res=>{
        let data =res.data.result
        context.commit('getEqBus',data)
      })
    },
    // --------------------------------
    getNameList: (context) => {//用户
      axios({
        method: 'get',
        url: '/common1/users',
        params: {
          suState: '正常'
        }
      }).then(res => {
        let arr = res.data.result.records
        context.commit('getUserName',arr)
        let dataName = []
      //一级
        for(let i in arr) {
          let obj1 = {
            value: arr[i].storefrontId,
            label: arr[i].storefrontName,
            children: []
          }
      //一级去重
          if (dataName.length < 1) {
            dataName.push(obj1)
          } else {
            let arr1 = []
            for (let ver1 of dataName) {
              arr1.push(ver1.value)
            }
            if (arr1.indexOf(obj1.value) < 0) {
              dataName.push(obj1)
            }
          }
      //二级
          for(let k in arr) {
            let obj2 = {
              value: arr[k].departmentId,
              label: arr[k].departmentName,
              children: []
            }
      //二级去重
            if (arr[i].storefrontId == arr[k].storefrontId) {
              if (obj1.children.length < 1) {
                obj1.children.push(obj2)
              } else {
                let arr2 = []
                for (let ver2 of obj1.children) {
                  arr2.push(ver2.value)
                }
                if (arr2.indexOf(obj2.value) < 0) {
                  obj1.children.push(obj2)
                }
              }
            }
      //三级
            for (let j in arr) {
              let obj3 = {
                value: arr[j].userCoding,
                label: arr[j].suStaffName,
              }
              if (arr[i].storefrontId == arr[j].storefrontId && arr[k].departmentId == arr[j].departmentId) {
                obj2.children.push(obj3)
              }
            }
          }
        }
        context.commit('getUserNameList',dataName)
      }).catch(err => {
        console.log(err)
      })
    },
    getSetList: (context) => {//初始设置
      axios({
        method: 'get',
        url: `/hotel/shortRentSetUps/1`,
      }).then(res => {
        console.log(res)
        // let arr = res.data.result
        // context.commit('getSetup',arr)
        // let dataName = arr
        res.data.result.jsrsuServiceCharge = res.data.result.jsrsuServiceCharge ? res.data.result.jsrsuServiceCharge : '[]'
        context.commit('getSetupList',res.data.result)
      }).catch(err => {
        console.log(err)
      })
    },
    getJcuGroupType: (context) => {//协议单位渠道类型
      axios({
        method: 'get',
        url: `/hotel/channelUnit/getAll`,
        params: {
          jcuType: '协议单位',
        }
      }).then(res => {
        context.commit('getGroupType',res.data.result)
      }).catch(err => {
        console.log(err)
      })
    },
    getCityList: (context) => {//城区
      axios({
        method: 'get',
        url: `/hotel/houseDict/district`,
      }).then(res => {
        context.commit('getCity',res.data.result)
      }).catch(err => {
        console.log(err)
      })
    },
    getCurrentUser: (context) => {
      axios({
        url: `/common1/users/${sessionStorage.getItem('spId')}`,
        method: "get"
      }).then(res => {
        context.commit('getUserInfo',res.data.result.records[0])
      }).catch(err => {
        console.log(err)
      })
    },
    async getBudgetList(context) { //查询变量表
    await axios({
      method: 'get',
      url: `/common1/companyVar/1`,
    }).then(res => {
      let arr = res.data.result.newFinancial?JSON.parse(res.data.result.newFinancial):''
      context.commit('getDetailsBudget', arr)
      let dataBudget = []
      for (let i of arr) {
        let obj1 = {
          value: i.nature,
          label: i.nature,
          children: []
        }
        for (let k of i.types) {
          let obj2 = {
            value: k.bigType,
            label: k.bigType,
            children: []
          }
          for (let j of k.type) {
            let obj3 = {
              value: j,
              label: j,
            }
            obj2.children.push(obj3)
          }
          obj1.children.push(obj2)
        }
        dataBudget.push(obj1)
      }
      let planTypeList = []
      let planList=[]
      let chargingPlan = res.data.result.chargingPlan?JSON.parse(res.data.result.chargingPlan):[]
      for (let i in chargingPlan) {
        if (chargingPlan[i].state == true) {
          planList.push(chargingPlan[i].postil)
          chargingPlan[i].postil = chargingPlan[i].postil + '底数'
          chargingPlan[i].value = ''
          planTypeList.push(chargingPlan[i])
        }
      }
      let achieveType=[]
      let list=res.data.result.achieveType ? JSON.parse(res.data.result.achieveType) : []
      for(let i in list){
        let obj1 = {
          value: list[i].type,
          label: list[i].type,
          children: []
        }
        for (let k in list[i].variable) {
          let obj2 = {
            value: list[i].variable[k],
            label: list[i].variable[k],
          }
          obj1.children.push(obj2)
        }
        achieveType.push(obj1)
      }
      console.log(achieveType)
      let obj={
        bankList: res.data.result.bankType?JSON.parse(res.data.result.bankType):[],
        intendedSourceList: res.data.result.intendedSource?JSON.parse(res.data.result.intendedSource):[],
        suppliesType: res.data.result.suppliesType?JSON.parse(res.data.result.suppliesType):[],
        assetsTypeList: res.data.result.assetsType?JSON.parse(res.data.result.assetsType):[],
        planTypeList : planTypeList,
        planList : planList,
        taskTypeList: res.data.result.taskType?JSON.parse(res.data.result.taskType):[],
        paymentTypeList: res.data.result.acountType?JSON.parse(res.data.result.acountType):[],
        detailsBudgetList: dataBudget,
        lateFeeRate: res.data.result.lateFeeRate,
        achieveSort: res.data.result.achieveSort?JSON.parse(res.data.result.achieveSort):[], //类型
        achieveType: achieveType, //类别
        achieveValidate: res.data.result.achieveValidate ? JSON.parse(res.data.result.achieveValidate) : [], //收益类型
        userType: res.data.result.userType ? JSON.parse(res.data.result.userType) : [],
        sectionTypeList: res.data.result.sectionType ? JSON.parse(res.data.result.sectionType) : [],//房型
        wxpayAccount: res.data.result.wxpayAccount,
        chargeReminder: res.data.result.chargeReminder,
        chargePercentage: res.data.result.chargePercentage,
        wxgzhName: res.data.result.wxgzhName, //微信公众号
        customerServiceTel: res.data.result.customerServiceTel, //客服电话
        companyAbbreviation: res.data.result.companyAbbreviation, //公司简称
        auditSwitch: res.data.result.auditSwitch,
        moneySwitch: res.data.result.moneySwitch,
        moneyValues: res.data.result.moneyValues,
        forcedFollowupSwitch: res.data.result.forcedFollowupSwitch,
        forcedFollowupValues: res.data.result.forcedFollowupValues,
        contractNums: res.data.result.contractNums,
        billNum: res.data.result.billNum,
        comfirmNum: res.data.result.comfirmNum,
        doorplateno: res.data.result.doorplateno,
        contractRiskControl: res.data.result.contractRiskControl,
        autoSendMessage: res.data.result.autoSendMessage,
        autoSendMessageDays: res.data.result.autoSendMessageDays,
        maxOverdue: res.data.result.maxOverdue,
        maxOverdueDays: res.data.result.maxOverdueDays,
        timeOnAndOff: res.data.result.timeOnAndOff,
        timeScope: res.data.result.timeScope?eval("(" + res.data.result.timeScope + ")"):{},
        meterReadingSwitch: res.data.result.meterReadingSwitch,
        meterReadingTimes: res.data.result.meterReadingTimes,
        doorLockAuthorization: res.data.result.doorLockAuthorization,
        onDutyRepairer: res.data.result.onDutyRepairer,
        gzhAd: res.data.result.gzhAd ? JSON.parse(res.data.result.gzhAd) : {},
        onDutyRepairerSwitch: res.data.result.onDutyRepairerSwitch,
        waterContinuityVariable: res.data.result.waterContinuityVariable,
        waterDailyVariable: res.data.result.waterDailyVariable,
        doorCardSystem: res.data.result.doorCardSystem
      }
      console.log(obj)
      context.commit('getCompanyVars', obj)
      context.commit('getDetailsBudgetList', dataBudget)
    }).catch(err => {
      console.log(err)
    })
    },
    //获取菜单列表
    getMenu(context){
      axios({
        url: '/common1/menus',
        method: 'get',
      }).then(res => {
        let menuList = res.data.result
        context.commit('getMenuList',menuList)
      }).catch(err => {
        console.log(err)
      })
    },
    //获取权限列表
    getAuthority(context){
      axios({
        url: '/common1/buttons/aliases',
        method: 'get',
      }).then(res => {
        let authe = res.data.result
        context.commit('getAuthorityList',authe)
      }).catch(err => {
        console.log(err)
      })
    },
    //获取页面权限
    getPageAuth(context){
      axios({
        url: '/common1/menus/obj',
        method: 'get',
      }).then(res => {
        console.log("获取页面权限成功");
        let pageAuth = res.data.result
        context.commit('getPageAuth',pageAuth)
      }).catch(err => {
        console.log(err)
      })
    },
    // 条件查询标签信息（含粉丝数量）
    getwxtag(context){
      axios({
        url: '/common3/wxTags/list',
        method: 'get',
      }).then(res => {
        context.commit('setwxTags', res.data.result.tagsList)
        console.log(res.data.result)
      })
    },
    setImageUrlName({commit, state}, url) {
      commit('setImageUrl', url)
    },
  },

  modules: {
    contextualModelState: contextualModelChild,
    // goUseVuex: learnToUseVuex,
  }

})
