import Vue from 'vue'
import Router from 'vue-router'
import routers from './routeritem'


const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
}

Vue.use(Router)
// console.log(process.env.VUE_APP_TITLE)
const router = new Router({
  // mode: 'history',
  mode: process.env.VUE_APP_TITLE == 'online' ? 'history' : "hash",
  // base: '/jd',
  routes: routers
})
import store from '../store'
// 白名单  免权限进入  linkRouter
let linkRouter = ['/hangweiyujing','/shebeiyujing','/faceLocal','/faceCloud']
router.beforeEach((to, from, next) => {
  if (to.path == '/welcome' && to.query && to.query.token) {
    sessionStorage.setItem('spId', 1)
    sessionStorage.setItem('way', 0)
    sessionStorage.setItem('token', to.token)
  }
  if (to.matched.some(record => record.meta.requireAuth)){ // 判断该路由是否需要登录权限
    if (sessionStorage.getItem('spId')) { // 判断当前的spid是否存在 ； 登录存入的spid
      console.log(to.path)
      if (store.state.pageAuth[`${to.path}`]
        || to.path == '/welcome'
        || to.fullPath == '/controlSetUp'
        || to.fullPath == '/timingControl'
        || to.fullPath == '/package'
        || to.fullPath == '/discount'
        || to.fullPath == '/visualization'
        || to.fullPath == '/roomControl'
      ) {
        next()
      } else {
        next({path:'/notfind'})
      }
    }
  }else if(linkRouter.includes(to.fullPath)){
    next(false)
  }
  else {
    next()
  }
})
export default router
