import { selectDevices } from '@/api/equipmentList'
import { equipmentInfo } from './local-host'

export default {
    state:() => ({
        locationDeviceList:[],
        loading1:false
    }),
    mutations: { 
        changeSelectDevices(state,playload){
            state.locationDeviceList = playload
        },
        setLoading(state,playload){
            state.loading1 = playload
        },
    },
    actions: { 
        getSelectDevices(context, params) {
            console.log('请求')
            context.commit('setLoading',true)
            selectDevices(params).then(res => {
                const data = res.data.result.map(item => {
                    // 
                    if(equipmentInfo.airConditioner.secondType.indexOf(item.devSecondType) > -1){
                        return { 
                            ...item,
                            flag:'未添加',
                            state:false,
                            setStatus:{
                                temperature: '',
                                pattern: '',
                                windSpeed: '',
                            }}
                    }
                    if(equipmentInfo.coolingAndHeatingLamp.secondType.indexOf(item.devSecondType) > -1){
                        return {
                            ...item,
                            flag:'未添加',
                            state:false,
                            setStatus:{
                                brightness: 1,
                                colorTemperature: 1,
                            }}
                    }
                    if(equipmentInfo.adjustingLamp.secondType.indexOf(item.devSecondType) > -1){
                        return {
                            ...item,
                            flag:'未添加',
                            state:false,
                            setStatus:{
                                brightness: 1,
                            }}
                    }
                    if(equipmentInfo.underfloorHeatingLamp.secondType.indexOf(item.devSecondType) > -1){
                        return {
                            ...item,
                            flag:'未添加',
                            state:false,
                            setStatus:{
                                brightness: 0,
                            }}
                    }
                    if(equipmentInfo.lnfraredAlarm.secondType.indexOf(item.devSecondType) > -1){
                        return {
                            ...item,
                            flag:'未添加',
                            state:false,
                            setStatus:{
                                devId: '',
                                switchs: '',
                                spacing: '',
                                threshold: '',
                                sensitivity: '',
                                periodVaule: '',
                                thresholdVaule: '',
                                sensitivityVaule: '',
                            }}
                    }
                    if(equipmentInfo.percentageCurtain.secondType.indexOf(item.devSecondType) > -1){
                        return {
                            ...item,
                            flag:'未添加',
                            state:false,
                            setStatus:{
                                percentCurtain: 0,
                            }}
                    }
                    return {...item,flag:'未添加',state:false}
                })
                context.commit('changeSelectDevices',data)
                context.commit('setLoading',false)
            }).catch(err => {
                context.commit('changeSelectDevices',[])
                context.commit('setLoading',false)
            })
        }
   }
}