import Vue from 'vue'
import App from './App.vue'
// import router from './router'
import router from  './route/index'

Vue.config.productionTip = false;

import ElementUI from 'element-ui';
Vue.productionTip = false;
// JS计算公式
import './utils/math'
import 'element-ui/lib/theme-chalk/index.css';
//全局修改默认配置，点击空白处不能关闭弹窗

import JsonViewer from 'vue-json-viewer'
Vue.use(JsonViewer)

import directives from './assets/directives';
Vue.directive('dialogDrag', directives)
//el-dialog 按住头部可移动属性 dialogDrag

ElementUI.Dialog.props.closeOnClickModal.default = false
Vue.use(ElementUI)
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import Vant from 'vant';
import 'vant/lib/index.css'
Vue.use(Vant)
// 自定义权限指令
import permission from './directive/permission'
Vue.use(permission)
// JS 计算
import JsMath from './utils/math'
Vue.prototype.$JsMath = JsMath
// Vue.direcive('hasPermi', hasPermi)
import './assets/iconfont/iconfont.css'//音乐播放图标

import VCascader from './components/casCader/index.vue'//全局注册级联选择器组件
Vue.component('VCascader', VCascader)
// 
import VirtualList from 'vue-virtual-scroll-list'

Vue.component('virtual-list', VirtualList)
import selfLog from './utils/selfLog'
Vue.prototype.selfLog=selfLog
import Axios from './utils/request'
Vue.prototype.$axios = Axios

//时间格式化全局过滤
import moment from 'moment'
Vue.filter('dateFormat', (str, partten = "YYYY-MM-DD HH:mm:ss") => {
  return moment(str).format(partten);
})
//时间格式化全局
Vue.prototype.DateFormat = function (str, partten="YYYY-MM-DD HH:mm:ss"){
  return moment(str).format(partten);
}

import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  ak: '8ed1f6fd13f5ff92783f9164f32825b7'
});

import * as qiniu from 'qiniu-js'
Vue.prototype.$qiniu = qiniu

import VDistpicker from 'v-distpicker'
Vue.component('v-distpicker', VDistpicker);

import vueAplayer from 'vue-aplayer'
Vue.use(vueAplayer)

import store from './store' //引入store
if (sessionStorage.getItem("store")) {
  store.replaceState(
    Object.assign({},
      store.state,
      JSON.parse(sessionStorage.getItem("store"))
    )
  );
  sessionStorage.removeItem("store")
}

//监听，在页面刷新时将vuex里的信息保存到sessionStorage里
window.addEventListener("beforeunload", () => {
  sessionStorage.setItem("store", JSON.stringify(store.state));
})

Vue.prototype.confirm = (msg,that) => {
  return new Promise((resolve, reject) => {
    that.$confirm(msg, {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }).then(() => {
      return resolve()
    }).catch(() => {
      return false
    }); 
  })
}

Vue.prototype.submitForm = (formName,that) => {
  let state = true
  console.log(formName,that)
  formName.forEach(element => {
    that.$refs[element].validate((valid) => {
      if (valid) {
        state = true
      } else {
        state = false
      }
    })
  })
  return state
}

Vue.prototype.resetForm = (formName,that) => {
  that.$nextTick(() => {
    formName.forEach(element => {
      // this.$refs[formName].resetFields();//清除name
      that.$refs[element].clearValidate();//不清除name
    })
  })
}

Vue.prototype.getIndex = (arr,row,state,that) => {
  for(let i in arr){
    if(arr[i] == row){
      if (state) {
        if (i < arr.length-1) {
          return arr[Number(i) + 1]
        } else {
          that.$message.warning('已是最后一条')
          // return arr[arr.length-1]
          return false
        }
      } else {
        if (i > 0) {
          return arr[Number(i) - 1]
        } else {
          that.$message.warning('已是第一条')
          return false
          // return arr[0]
        }
      }
    }
  }
}

Vue.prototype.randomNumber = () => { //生成随机编号
  let strNumber = '';
  let myDate = new Date();
  let year = myDate.getFullYear();
  let month = myDate.getMonth() + 1;
  if (month >= 1 && month <= 9) {
    month = "0" + month;
  }
  let day = myDate.getDate();
  if (day >= 0 && day <= 9) {
    day = "0" + day;
  }
  let rnd = "";
  for (let i = 0; i < 6; i++) {
    rnd += Math.floor(Math.random() * 10);
  }
  let yearStr = year.toString().substring(2, 4);
  strNumber = yearStr + month + day + rnd;
  return strNumber;
}
Vue.prototype.timer = ''
Vue.prototype.searchOnkeyup = function searchOnkeyup(callback) {//筛选框input事件防抖
  if (this.timer){
    clearTimeout(this.timer)
  }
  this.timer = setTimeout( () => {
    callback(1)//传入的分页请求函数
  }, 800)
}

Vue.config.productionTip = false
Vue.prototype.isError = (error,that) => {//判断状态码自定义错误信息
  let co = sessionStorage.getItem("co");
  switch(error.response.status){
    case 401:
      that.$message({
        showClose: true,
        duration: 6000,
        message: `${error.response.data.msg}`,
        type: 'error'
      })
      that.$router.replace(`/saas/${co}`);
      break
    default:
      that.$message({
        showClose: true,
        duration: 6000,
        message: `${error.response.data.msg}`,
        type: 'error'
      })
  }
}

Vue.prototype.numberChinese = function numberChinese (str) {//金额转中文
    let num = parseFloat(str);
    let strOutput = "",
        strUnit = '仟佰拾亿仟佰拾万仟佰拾元角分';
    num += "00";
    let intPos = num.indexOf('.');
    if (intPos >= 0){
        num = num.substring(0, intPos) + num.substr(intPos + 1, 2);
    }
    strUnit = strUnit.substr(strUnit.length - num.length);
    for (let i=0; i < num.length; i++){
        strOutput += '零壹贰叁肆伍陆柒捌玖'.substr(num.substr(i,1),1) + strUnit.substr(i,1);
    }
    return strOutput.replace(/零角零分$/, '整').replace(/零[仟佰拾]/g, '零').replace(/零{2,}/g, '零').replace(/零([亿|万])/g, '$1').replace(/零+元/, '元').replace(/亿零{0,3}万/, '亿').replace(/^元/, "零元")
}

import '../theme/index.css'

//打印
import Print from 'vue-print-nb'
Vue.use(Print);

import vueQr from 'vue-qr'
Vue.use(vueQr);

//富文本
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'


//过滤器
import { dateFtt } from './assets/utils'
Vue.filter('dateFilter', function (val) {
    if (val  == "0") {
        return ""
    }else if(val){
        return dateFtt('yyyy-MM-dd hh:mm:ss', new Date(Number(val) * 1000))
    }else{
        return ""
    }

})

Vue.filter('PppTypeFilter', function (val) {
    switch (val) {
        case "any":
            return "不限"
        case "web":
            return "WEB-账户"
        case "pppoe":
            return "PPPoE"
        case "pptp":
            return "PPtP"
        case "l2tp":
            return "L2TP"
        case "ovpn":
            return "OpneVPN"
    }
})

Vue.filter('statusFilter1', function (val) {
    switch (val) {
        case 'yes':
            return '停用'
        case 'no':
            return '启用'
    }
})
Vue.filter('statusFilter2', function (val) {
    switch (val) {
        case 'yes':
            return '启用'
        case 'no':
            return '停用'
    }
})
Vue.filter('discountStatusFilter2', function (val) {
    switch (val) {
        case 0:
            return '正常'
        case 1:
            return '注销'
    }
})
import './utils/quill.snow.css'

// console.log = function () {
//   return false;
//  }
let development=process.env.NODE_ENV == 'development' ? true : false;
console.log(development)
if(!development){
console.log = function () {
  return false;
}
}
Vue.use(VueQuillEditor)

const on = Vue.prototype.$on
// Vue.prototype.$on = function (event, func) {
//   let timer;
//   let flag = true;
//   let newFunc = func
//   if (event == 'click') {
//     newFunc = function () {
//       if(flag) {
//         func.apply(this, arguments)
//         flag = false
//       }
//       clearTimeout(timer)
//       timer = setTimeout(function () {
//         flag = true
//       }, 1000)
//     }
//   }
//   on.call(this, event, newFunc)
// }

new Vue({
  router,
  store,
  render: h => h(App),
  beforeCreate() {
		Vue.prototype.$bus = this //全局事件总线
	},
}).$mount('#app')

// 菜单
import VueContextMenu from 'vue-contextmenu'
Vue.use(VueContextMenu)